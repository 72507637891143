@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Roboto','sans-serif';
  background-color: #000;
}

.project-page {
  max-width: 1200px;
  margin: 0 auto;
  margin-top:40px;
  padding: 50px;
}

h1{
  font-family: 'Roboto','sans-serif';
  font-size: 1.9rem;
  font-weight: 1700;
  color: #00FF00;
}

.project-list {
  list-style: none;
  margin-top: 50px;
  padding: 0;
}

.project-list-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #001a00;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(152, 251, 152, 0.6);
  margin-bottom: 20px;
  overflow: hidden;
  transition: transform 0.3s ease-out;
}

a {
  text-decoration: none;
  color: #98FB98;
}

.project-list-item:hover {
  transform: scale(1.1);
  box-shadow: 0px 8px 15px rgba(152, 251, 152, 0.9);
}

.image-container {
  flex-basis: 40%;
}

.project-list-item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-content {
  flex-basis: 60%;
  padding: 20px;
  transition: opacity 0.3s ease-out;
  background-color: rgba(0, 26, 0, 0.9);
  color: #98FB98;
}

.project-content h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  color:#00FF00;
  transition: color 0.3s ease-out;
}

.project-content p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
  transition: opacity 0.3s ease-out;
  color: #98FB98;
}

.project-list-item:hover .project-content {
  background-color: #000;
  color: #98FB98;
}

.project-list-item:hover .project-content h3 {
  color: #FFFFFF;
  font-size: 30px;
}

.project-list-item:hover .project-content p {
  opacity: 0;
}

.project-list-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.project-list-item:hover::before {
  opacity: 1;
}
