.navbar {
  display: flex;
  align-items: center;
  background-color: #000000; /* changed to black */
  height: 80px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 4px 6px rgba(0, 255, 0, 0.1); /* added green shadow */
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px;
}

.navbar-name {
  font-family: 'Courier New', monospace;
  font-size: 1.5rem;
  font-weight: 700;
  color: #0f0;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  animation: color-change 5s infinite;
}

.navbar-name:hover {
  color: #00ff00;
  cursor: pointer;
  font-size:1.8rem;
}

.navbar-menu {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.navbar-item {
  margin-left: 30px;
}

.navbar-link {
  display: flex;
  align-items: center;
  color: #0f0;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  font-family: 'Courier New', monospace;
  font-size: 1.1rem;
  font-weight: 500;
  transition: 0.2s ease-in-out;

  /* animation: color-change 5s infinite; */
}



.navbar-link:hover .navbar-link-item,
.navbar-link:active .navbar-link-item {
  color: #ffffff; /* White on hover and active */
  font-size: 1.3rem;
  font-weight: bold;
}



.navbar-link-item {
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin-right: 8px;
}

.navbar-link:hover .navbar-icon,
.navbar-link:active .navbar-icon {
  color: #ffffff; /* White on hover and active */
}

@media (max-width: 768px) {
  /* other styles remain the same */

  .navbar-hamburger {
    /* other styles remain the same */
    color: #0f0; /* changed to matrix green */
  }

  .navbar-menu-item-link {
    /* other styles remain the same */
    color: #0f0; /* changed to matrix green */
  }

  .navbar-menu-item-link:hover {
    color: #00ff00;
  }
}

@keyframes color-change {
  0% { color: #0f0; }
  50% { color: #009100; }
  100% { color: rgb(0, 63, 0); }
}