@import url('https://fonts.googleapis.com/css?family=Courier+Prime&display=swap');

body {
  font-family: 'Courier New', monospace;
  color: #0f0;
  background-color: black;
}

.home-container {
  margin-top: 80px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.home-box {
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.4); /* semi-transparent black */
  border-radius: 15px;
  color: #0f0; /* matrix green */
  box-shadow: 0px 0px 10px #0f0; /* green glow */
  max-width: 70%; /* limit the width to avoid overflow */
}

.typewriter {
  font-size: 1.3rem;
  overflow: hidden;
  border-right: .15em solid orange;
  white-space: pre-wrap;
  margin: 0 auto;
  letter-spacing: .15em;
  animation:
    typing 2.5s steps(40, end),
    blink-caret .75s step-end infinite;
  position: relative;
  z-index: 2;
  /* color: #0f0; matrix green */
  color: #ffff;
  font-family: 'Courier Prime', 'Courier Code', monospace;

}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

.home-title {
  font-size: 4rem;
  font-weight: bold;
  color: #0f0;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  font-family: 'Courier Prime', 'Courier Code', monospace;
}

.quote-container, .weather-container {
  padding: 1rem;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6); /* semi-transparent black */
  text-align: center;
  color: #0f0; /* matrix green */
  font-family: 'Courier New', monospace; /* monospace font */
  box-shadow: 0px 0px 10px #0f0; /* green glow */
  max-width: 80%; /* limit the width to avoid overflow */
  word-wrap: break-word; /* allow long words to break and go to the next line */
  z-index: 2;
}

.quote-text, .weather-text {
  color: #0f0;
  font-weight: bold;
}

.weather-container {
  position: absolute;
  top: 80px;
  right: 20px;
  margin: 20px;
  z-index: 2;
}

.weather-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.weather-icon {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  filter: hue-rotate(90deg); /* make the weather icon green */
}

