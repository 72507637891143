@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap");

body {
  font-family: "Roboto", sans-serif;
  background-color: #000;
}

h2,
h3 {
  font-family: "Merriweather", serif;
  color: #98FB98;
}

.contact-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #001a00;
  color: #98FB98;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.contact-intro {
  flex: 1;
  padding-right: 2rem;
  text-align: left;
}

.contact-info {
  flex: 1;
  padding-left: 2rem;
  text-align: left;
}

.contact-info p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.contact-info a {
  color: #98FB98;
  text-decoration: none;
  transition: color 0.3s;
}

.contact-info a:hover {
  color: #FFFFFF;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #001a00;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(152, 251, 152, 0.6), 0 1px 2px rgba(152, 251, 152, 0.24);
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #98FB98;
  border-radius: 4px;
  transition: border-color 0.3s;
  background-color: #000;
  color: #98FB98;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #FFFFFF;
}

.contact-form textarea {
  resize: none;
  min-height: 150px;
}

.contact-form button {
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  background-color: #98FB98;
  border: none;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;
}

.contact-form button:hover {
  background-color: #FFFFFF;
  color: #000;
}

.form-sent-message {
  margin-top: 1rem;
  color: #FFFFFF;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .contact-content {
    flex-direction: column;
  }

  .contact-intro,
  .contact-info {
    padding-right: 0;
    padding-left: 0;
  }

  .contact-info {
    margin-top: 2rem;
  }
}

.intro{
  font-size: 0.8rem;
}
