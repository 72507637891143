.about-page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.navbar-container {
  height: 80px; /* set height of navbar */
}

.content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 80%;
  align-self: center;
}

.download-button {
  align-self: flex-end;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #006400; /* Dark Green */
  color: #98FB98; /* Pale Green */
  text-decoration: none;
  font-size: 25px;
  width: 100%; /* set the button to fill the width of its parent */
  border: 2px solid #98FB98; /* Pale Green Border */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 3px #006400; /* Shadow for 3D effect */
}

.download-button:hover {
  background-color: #00FF00; /* Pale Green */
  color: #000; /* Black */
  transform: translateY(-2px); /* Small upward motion on hover */
  box-shadow: 0 2px #006400; /* Adjusting shadow for 3D effect */
  font-weight:bolder;
  font-size:1.5rem;
}
