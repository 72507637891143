.computer-vision-project {
  max-width: 80%;
  margin-top: 70px;
  padding: 50px;
  align-items: center;
  color: #ddd;
  background-color: #111;
}

.computer-vision-project h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #00ff00;
}

.project-container {
  display: flex;
  margin: 0 auto;
  position: flex;
  width: 80%;
}

.project-readme {
  flex: 1;
  width: 90%;
}

.toc-link {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.toc-link:hover {
  color: #00ff00;
  cursor: pointer;
}

.toc-link:focus {
  outline: none;
  color: #05d005;
}

.project-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  color: #ddd;
}

.table-of-contents {
  flex: 0 0 300px;
  margin-right: 24px;
  position: sticky;
  top: 30px;
  background-color: #111;
  color: #ddd;
  border: 1px solid #e5e5e5;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  max-height: calc(100vh - 80px);
}
